import { navigate } from 'gatsby'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useContainer } from 'unstated-next'
import UnauthenticatedHeader from '../components/UI/Header/UnauthenticatedHeader'
import { CurrentUserState } from '../state/CurrentUserState'
import { colors } from '../theme'
import Base from './base'

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  > :nth-child(2) {
    flex: 1;
    height: 100%;
  }
`

const Content = styled.div`
  background-color: ${colors.purple1};
`

const UnauthenticatedLayout = ({ children }) => {
  const state = useContainer(CurrentUserState)

  useEffect(() => {
    if (state.isAuthenticated === true && state.currentUser) {
      if(state.signUp && state.currentUser.subscription.type === "kid-first") navigate('/welcome')
      else navigate('/')
    }
  }, [state.isAuthenticated, state.currentUser])

  if (state.isAuthenticated) return null

  return (
    <Base>
      <Wrapper>
        <UnauthenticatedHeader />
        <Content>
          {children}
        </Content>
      </Wrapper>
    </Base>
  )
}

UnauthenticatedLayout.propTypes = {
  children: PropTypes.node.isRequired
}

export default UnauthenticatedLayout
