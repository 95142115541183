import { useApolloClient } from '@apollo/client'
import { useLocation } from '@reach/router'
import { navigate } from 'gatsby'
import useAmplitude from 'hooks/useAmplitude'
import { useEffect, useState } from 'react'
import { CurrentUserState } from '../../../state/CurrentUserState'
import VERIFY_EMAIL from './graphql/verifyEmail.graphql'

const useData = () => {
  const client = useApolloClient()
  const location = useLocation()
  const { login } = CurrentUserState.useContainer()
  const [loading, setLoading] = useState(true)
  const [verified, setVerified] = useState(false)
  const [error, setError] = useState<any>(null)
  const { logEvent } = useAmplitude()

  const fetch = async () => {
    const params = new URLSearchParams(location.search)
    const token = params.get('token')
    const { data, errors } = await client.mutate({
      mutation: VERIFY_EMAIL,
      variables: {
        token
      },
      errorPolicy: 'all'
    })
    setLoading(false)
    if (errors) {
      setError(errors[0])
      return
    }
    const { _id, sharedSecretKey } = data.verifyEmail
    const redirectTo = params.get('redirectTo') || '/'
    setVerified(true)
    logEvent('Dashboard: Email verified')
    setTimeout(async () => {
      await login(_id, sharedSecretKey)
      navigate(redirectTo)
    }, 3000)
  }

  useEffect(() => {
    fetch()
  }, [])

  return {
    loading,
    verified,
    error
  }
}

export default useData
