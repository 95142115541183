import Loader from 'components/UI/Loader'
import { navigate } from 'gatsby'
import { Button } from 'nzk-react-components'
import React from 'react'
import styled from 'styled-components'
import useData from './useData'

const Wrapper = styled.div`
  color: #fff;
  padding: 50px 20px 20px;
`

const Title = styled.div`
  font-size: 22px;
  line-height: 26px;
  text-shadow: 0px 3px #afafaf, 0px 6px rgba(0, 0, 0, 0.3);
  color: #ffffff;
  font-family: 'Rammetto One', cursive;
  margin-bottom: 0px;
  text-align: center;
  margin-bottom: 20px;
`

const Message = styled.div`
  text-align: center;
`

const Actions = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`

const VerifyEmailPage = () => {
  const { loading, verified, error } = useData()

  if (loading) return <Wrapper><Loader color='#fff' /></Wrapper>

  if (!loading && verified) return <Wrapper>
    <Title>Success!</Title>
    <Message>
      <p>Your email has been successfully verified.</p>
      <p>We'll redirect you in 3 seconds.</p>
    </Message>
  </Wrapper>

  if (!loading && error) return <Wrapper>
    <Title>Error!</Title>
    <Message>It looks like an error has occured...</Message>
    <Actions>
      <Button size='regular' theme='primary' onClick={() => navigate('/')}>Home</Button>
    </Actions>
  </Wrapper>

  return null
}

export default VerifyEmailPage
