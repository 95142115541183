const useAmplitude = () => {
  const available = typeof amplitude !== 'undefined'
  const logEvent = (event: string, extras?: any) => {
    if (!available) return
    amplitude.getInstance().logEvent(event, extras)
  }

  return { logEvent }
}

export default useAmplitude
