import VerifyEmailPage from 'components/pages/VerifyEmailPage';
import UnauthenticatedLayout from 'layouts/UnauthenticatedLayout';
import React from 'react';

const VerifyEmail = (props) => {
  if (typeof window === 'undefined') return null

  return <UnauthenticatedLayout>
    <VerifyEmailPage {...props} />
  </UnauthenticatedLayout>
}

export default VerifyEmail
